import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { graphql } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { NavbarOnly } from '_templates'
import { Container, Heading, Text, Image } from '_atoms'
import { Hero } from '_molecules'
import { SEO } from '_organisms'
import { AppContext } from '_context'
import variables from '_config/css-variables'
import iconLinkHome from '_images/pages/404/home.svg'
import iconLinkApps from '_images/pages/404/apps.svg'
import iconEmpty from '_images/pages/404/empty.svg'

import styles from './styles.module.css'

const NotFoundPage = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const { notFoundTitle, notFoundDescription, notFoundThumbnail } = data.contentfulPageMetatags

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    if (y <= 90) {
      setNavbarColor('transparent')
      setNavbarLight(false)
    } else setNavbarColor(variables['color-primary'])
  }, [setNavbarColor, setNavbarLight, y])

  return (
    <NavbarOnly>
      <SEO
        title={notFoundTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={notFoundDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={notFoundThumbnail.file.url}
      />

      <div className={styles.page}>
        <Hero
          title="contact.hero.title"
          subTitle="contact.hero.subtitle"
          className={styles.heroMobile}
          customContent={
            <div className={styles.heroMobileContainer}>
              <Fade distance="25%" top>
                <Heading type="h1" color="white" className={styles.heroTitle} bold fixedSize>
                  <FormattedMessage id="404.title1" />
                  <br />
                  <FormattedMessage id="404.title2" />
                </Heading>
              </Fade>
              <Fade distance="25%" bottom>
                <div className={styles.heroLinks}>
                  <Link to="/" className={styles.link}>
                    <Text size="24" color="white" className={styles.linkText}>
                      <FormattedMessage id="404.home" />
                    </Text>
                    <Image className={styles.linkIcon} src={iconLinkHome} alt="" />
                  </Link>
                  <Link to="/portfolio" className={styles.link}>
                    <Text size="24" color="white" className={styles.linkText}>
                      <FormattedMessage id="404.projects" />
                    </Text>
                    <Image className={styles.linkIcon} src={iconLinkApps} alt="" />
                  </Link>
                </div>
              </Fade>
            </div>
          }
        />

        <Container>
          <Row className={styles.pageRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.heroDesktop}>
              <div className={styles.heroContent}>
                <Fade distance="25%" top>
                  <Heading type="h1" color="white" className={styles.heroTitle} bold>
                    <FormattedMessage id="404.title1" />
                    <br />
                    <FormattedMessage id="404.title2" />
                  </Heading>
                </Fade>
                <Fade delay={200} distance="25%" bottom>
                  <div className={styles.heroLinks}>
                    <Link to="/" className={styles.link}>
                      <Text size="24" color="white" className={styles.linkText}>
                        <FormattedMessage id="404.home" />
                      </Text>
                      <Image className={styles.linkIcon} src={iconLinkHome} alt="" />
                    </Link>
                    <Link to="/portfolio" className={styles.link}>
                      <Text size="24" color="white" className={styles.linkText}>
                        <FormattedMessage id="404.projects" />
                      </Text>
                      <Image className={styles.linkIcon} src={iconLinkApps} alt="" />
                    </Link>
                  </div>
                </Fade>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.empty}>
              <Fade delay={400} distance="25%" bottom>
                <>
                  <Image className={styles.emptyIcon} src={iconEmpty} alt="" />

                  <Text size="24" color="dark" className={styles.emptyText}>
                    <FormattedMessage id="404.legend" />
                  </Text>
                </>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </NavbarOnly>
  )
}

export const query = graphql`
  query NotFoundPageContent($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      notFoundDescription
      notFoundTitle
      notFoundThumbnail {
        file {
          url
        }
      }
    }
  }
`

NotFoundPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default NotFoundPage
